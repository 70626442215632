export enum MatomoCategory {
  DATE_RANGE_PICKER = "Change date-range picker",
  MOMENT_SELECTOR = "Select moment",
  DATE_RANGE_SHORTCUT = "Select date-range shortcut",
  REFRESH_DATA = "Refresh data",
  HOME_CARD = "Home expandable card grafana",
  GRAFANA = "Grafana",
}
export enum DateRangeSelectorEvent {
  H24 = "24h",
  H72 = "72h",
  D7 = "7 jours",
  M1 = "1 mois",
  M3 = "3 mois",
  M6 = "6 mois",
  Y1 = "1 an",
}
