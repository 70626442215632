import { useQuery } from "@apollo/client";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import BoltIcon from "@mui/icons-material/Bolt";
import {
  Collapse,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  differenceInDays,
  endOfDay,
  format,
  formatRelative,
  startOfDay,
  subDays,
} from "date-fns";
import { fr } from "date-fns/locale";
import * as React from "react";
import { HOME_QUERY, LAST_RECORD } from "../../GraphQL/queries";
import {
  DailyRecordAverage,
  ElectricityStatistics,
  HomeQuery,
  LastRecordQuery,
  LocationEnum,
  Record,
  RecordTypeEnum,
} from "../../__generated__/graphql";
import { MatomoCategory } from "../../context/matomo/types";
import { translateWeatherDescription } from "../../translations/getT";
import { displayElectricityCost, shouldSeeLiveTab } from "../../utils";
import MyDateRangePicker from "../MyDateRangePicker";
import { MyFab } from "../MyFab";
import { ColoredLocationAvatar } from "../weather/ColoredLocationAvatar";
import { MyLineGraph } from "../weather/MyLineGraph";

const Home: React.FC = () => {
  const now = new Date();
  const [date, setDate] = React.useState<[Date, Date] | null>([
    startOfDay(subDays(now, 14)),
    now,
  ]);
  const { loading: loadingHome, data: dataHome } = useQuery(HOME_QUERY, {
    fetchPolicy: "cache-and-network",
    variables: {
      input: {
        from: date ? date[0] : startOfDay(subDays(now, 14)),
        to: date ? date[1] : now,
      },
    },
  });
  const { loading: loadingLive, data: dataLive } = useQuery(LAST_RECORD, {
    fetchPolicy: "cache-and-network",
    variables: {
      data: { type: RecordTypeEnum.Temperature },
    },
  });

  return (
    <Grid sx={{ marginBottom: "60px", marginTop: 2 }}>
      <MyFab />
      <MyDateRangePicker range={date} setRange={setDate} />
      <CollapsibleTable
        dataHome={dataHome}
        dataLive={dataLive}
        loading={loadingHome || loadingLive}
      />
    </Grid>
  );
};

interface CollapsibleTableProps {
  dataHome?: HomeQuery;
  dataLive?: LastRecordQuery;
  loading: boolean;
}
const CollapsibleTable = (props: CollapsibleTableProps) => {
  const { dataHome, loading, dataLive } = props;
  const outsideRecord = dataLive?.lastRecord.filter(
    (el) => el.location === LocationEnum.Outside
  )[0];
  return (
    <TableContainer sx={{ marginBottom: "60px" }}>
      <Table aria-label="collapsible table">
        <TableHead sx={{ position: "sticky", top: 0 }}>
          <TableRow>
            <TableCell sx={{ textAlign: "center" }} />
            <TableCell sx={{ textAlign: "center" }}>
              <ColoredLocationAvatar
                location={LocationEnum.LivingRoom}
                fontColorOnly
              />
            </TableCell>
            <TableCell sx={{ textAlign: "center" }}>
              <ColoredLocationAvatar
                location={LocationEnum.Bedroom}
                fontColorOnly
              />
            </TableCell>
            <TableCell sx={{ textAlign: "center" }}>
              <ColoredLocationAvatar
                location={LocationEnum.Outside}
                fontColorOnly
              />
            </TableCell>
            <TableCell sx={{ textAlign: "center" }}>
              <BoltIcon />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataLive ? (
            <Row
              key={"live"}
              loading={loading}
              data={dataLive.lastRecord}
              date={new Date()}
              outsideRecord={outsideRecord}
            />
          ) : (
            <SkeletonRow />
          )}

          {dataHome
            ? dataHome.home.statistics.map((row) => (
                <Row
                  key={row.date}
                  loading={loading}
                  data={row.records}
                  electricity={row.electricity}
                  date={row.date}
                />
              ))
            : Array.from({ length: 10 }).map((_, index) => (
                <SkeletonRow key={index} />
              ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const SkeletonRow = () => {
  return (
    <TableRow>
      {Array.from({ length: 5 }).map((_, index) => (
        <TableCell key={index}>
          <Skeleton variant="text" animation="wave" />
        </TableCell>
      ))}
    </TableRow>
  );
};

interface RowProps {
  loading: boolean;
  date: Date;
  data?: Pick<DailyRecordAverage, "location" | "value">[];
  electricity?: Pick<ElectricityStatistics, "cost">;
  outsideRecord?: Pick<Record, "weather_description" | "weather_icon">;
}
const Row = (props: RowProps) => {
  const { loading, data, date, electricity, outsideRecord } = props;
  const isNow = differenceInDays(new Date(), new Date(date)) === 0;

  const [open, setOpen] = React.useState(!!isNow);
  const { trackEvent } = useMatomo();

  const handleExpandClick = () => {
    setOpen(!open);
    if (!open) {
      trackEvent({
        category: MatomoCategory.HOME_CARD,
        action: "expand",
      });
    } else {
      trackEvent({
        category: MatomoCategory.HOME_CARD,
        action: "collapse",
      });
    }
  };

  let title = "";
  if (differenceInDays(new Date(), new Date(date)) > 6) {
    title = format(new Date(date), "EE dd LLLL", {
      locale: fr,
    });
  } else if (isNow) {
    title = "En direct";
  } else {
    title = formatRelative(new Date(date), new Date(), {
      locale: fr,
    })
      .split("à")[0]
      .split("dernier")[0];
  }

  return (
    <React.Fragment>
      <TableRow
        onClick={handleExpandClick}
        sx={{ backgroundColor: isNow ? "#e2F1F4" : null }}
      >
        <TableCell
          component="th"
          scope="row"
          sx={{ p: 0.2, fontWeight: 500, textAlign: "center" }}
        >
          {loading ? (
            <Skeleton
              key={date + "title"}
              variant="text"
              animation="wave"
              width={80}
            />
          ) : (
            <>
              <Typography sx={{ fontWeight: isNow ? 550 : 0 }}>
                {title}
              </Typography>
              {isNow && outsideRecord && (
                <Typography variant="caption">
                  {translateWeatherDescription(
                    outsideRecord.weather_description
                  )}
                </Typography>
              )}
            </>
          )}
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>
          {!data || loading ? (
            <Skeleton
              key={date + "livingRoom"}
              variant="text"
              animation="wave"
            />
          ) : (
            <Typography sx={{ fontWeight: isNow ? 550 : 0 }}>
              {displayTemperature(data, LocationEnum.LivingRoom)}
            </Typography>
          )}
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>
          {!data || loading ? (
            <Skeleton
              key={date + "livingRoom"}
              variant="text"
              animation="wave"
            />
          ) : (
            <Typography sx={{ fontWeight: isNow ? 550 : 0 }}>
              {displayTemperature(data, LocationEnum.Bedroom)}
            </Typography>
          )}
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>
          {!data || loading ? (
            <Skeleton
              key={date + "livingRoom"}
              variant="text"
              animation="wave"
            />
          ) : (
            <Typography sx={{ fontWeight: isNow ? 550 : 0 }}>
              {displayTemperature(data, LocationEnum.Outside)}
            </Typography>
          )}
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>
          {!electricity?.cost || loading ? (
            isNow && !!outsideRecord?.weather_icon ? (
              <img
                src={`https://openweathermap.org/img/wn/${outsideRecord?.weather_icon}@2x.png`}
                alt={outsideRecord?.weather_description ?? "icon"}
                width={40}
                height={40}
              />
            ) : (
              <Skeleton
                key={date + "livingRoom"}
                variant="text"
                animation="wave"
                width={30}
              />
            )
          ) : (
            <Typography sx={{ fontWeight: isNow ? 550 : 0 }}>
              {displayElectricityCost(electricity.cost)}
            </Typography>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0, textAlign: "center" }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {data && (
              <MyLineGraph
                type={RecordTypeEnum.Temperature}
                defaultZoom={[
                  startOfDay(new Date(date)),
                  new Date() > endOfDay(new Date(date))
                    ? endOfDay(new Date(date))
                    : new Date(),
                ]}
                showDateRangePicker={false}
                showShortcutChips={false}
                withElectricity={
                  shouldSeeLiveTab || new Date() > endOfDay(new Date(date))
                }
              />
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const displayTemperature = (
  data: Pick<DailyRecordAverage, "location" | "value">[],
  location: LocationEnum
) => {
  const temp = data.filter((el) => {
    return el.location === location;
  })[0].value;
  const val = Math.round(10 * temp) / 10;
  return val.toLocaleString() + "°";
};

export default Home;
